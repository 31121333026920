<template>
  <b-overlay :show="loading">
    <b-card v-if="eventData && eventData._id" class="mt-5">
      <form-wizard
        title="Event Registration"
        subtitle="Complete the form below to register for this event"
        color="#011151"
        class="mb-3"
        shape="square"
        layout="horizontal"
        finish-button-text="Reserve now"
        back-button-text="Previous"
        @on-complete="onReserveNow"
      >
        <tab-content title="Accommodation" :before-change="validateAccommodationForm">
          <validation-observer ref="accommodationForm">
            <b-form ref="form">
              <div v-if="eventData.require_accommodation">
                <div class="mb-1">
                  <b-form-group v-slot="{ ariaDescribedby }" label="Select Accommodation" label-for="accommodation">
                    <validation-provider #default="{ errors }" name="accommodation" vid="accommodation" rules="required">
                      <b-form-radio-group
                        id="radio-slots"
                        v-model="selectedAccommodation"
                        :aria-describedby="ariaDescribedby"
                        name="accommodation"
                        value-field="_id"
                        text-field="title"
                        stacked
                      >
                        <b-form-radio 
                          v-for="(accommodation, i) in eventData.accommodations" 
                          :key="i" :value="accommodation._id" 
                          class="mb-2"
                          :disabled="accommodation.reservations >= (accommodation.number_of_rooms * accommodation.number_of_persons_per_room)"
                        >
                          <div class="d-flex flex-wrap border border-rounded px-2 pt-1 pb-2 w-100">
                            <div class="flex-grow" style="border-radius: 5px; flex-grow: 1; max-width: 300px;">
                              <h3 class="m-0 mb-1">
                                <span class="mr-1">
                                  <feather-icon
                                    icon="HomeIcon"
                                    size="16"
                                  />
                                </span>
                                {{ accommodation.title }}
                              </h3>
                              
                              <p class="text-muted m-0">
                                Center: {{ accommodation.event_center.title }}
                              </p>
                              <p class="text-muted m-0">Block: {{ accommodation.event_block.title }}</p>
                              <p class="text-muted m-0">Persons per room: {{ accommodation.number_of_persons_per_room }}</p>
                              <p :class="computeRoomsAvailable(accommodation) > 1 ? 'text-primary m-0' : 'text-danger m-0'">Rooms available: {{ computeRoomsAvailable(accommodation) }}</p>
                              <p class="text-info m-0">Price: {{ accommodation.is_free ? 'Free' : `GHS ${formatMoney(accommodation.price)}` }}</p>
                              <p v-if="!accommodation.is_free" class="text-info m-0">Allow part payment?: {{ accommodation.payment_installment ? 'Yes' : 'No' }}</p>
                            </div>
                            <b-img
                              v-if="getValueFromSource(accommodation, 'thumbnail_image.path')"
                              :alt="accommodation.title"
                              fluid
                              height="90"
                              width="150"
                              class="card-img-top"
                              style="max-width: 150px; max-height: 90px;"
                              :src="getValueFromSource(accommodation, 'thumbnail_image.path')"
                            />
                          </div>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div v-else>
                <b-alert show variant="info" class="p-2">
                  <p>No accommodation required. Proceed</p>
                </b-alert>
              </div>
            </b-form>
          </validation-observer>
        </tab-content>

        <tab-content title="Canteen" :before-change="validateCanteenForm">
          <validation-observer ref="canteenForm">
            <b-form ref="form">
              <div v-if="eventData.require_canteen">
                <div class="mb-1">
                  <b-form-group v-slot="{ ariaDescribedby }" label="Select Canteen/Caterer" label-for="canteen">
                    <validation-provider #default="{ errors }" name="Select Canteen/Caterer" vid="canteen" rules="required">
                      <b-form-radio-group
                        id="canteen-radio"
                        v-model="selectedCanteen"
                        :aria-describedby="ariaDescribedby"
                        name="canteen"
                        value-field="_id"
                        text-field="title"
                        stacked
                      >
                        <b-form-radio 
                          v-for="(canteen, i) in eventData.canteens" 
                          :key="`canteen-${i}`" :value="canteen._id" 
                          class="mb-2"
                          :disabled="canteen.reservations >= canteen.capacity"
                        >
                          <div class="border border-rounded px-2 pt-1 pb-2 w-100 d-flex justify-content-between flex-wrap" style="border-radius: 5px;">
                            <div>
                              <h3 class="m-0 mb-1">
                                <span class="mr-1">
                                  <feather-icon
                                    icon="CoffeeIcon"
                                    size="16"
                                  />
                                </span>
                                {{ canteen.title }}
                              </h3>
                              
                              <p :class="canteenSlotsAvailable(canteen) > 1 ? 'text-primary m-0' : 'text-danger m-0'">Slots available: {{ canteenSlotsAvailable(canteen) }}</p>
                              <p class="text-muted">More info: {{ canteen.description || '-' }}</p>
                            </div>

                            <div v-if="getValueFromSource(canteen, 'thumbnail_image.path')">
                              <b-img
                                :src="getValueFromSource(canteen, 'thumbnail_image.path')"
                                width="400"
                                rounded
                                fluid
                                class="mr-2 mb-1 mb-md-0"
                              />
                            </div>
                          </div>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div v-else>
                <b-alert show variant="info" class="p-2">
                  <p>No canteen required. Proceed</p>
                </b-alert>
              </div>
            </b-form>
          </validation-observer>
        </tab-content>

        <tab-content title="Merchandise (optional)">
          <div v-if="eventData.require_product">
            <div class="d-flex justify-content-between">
              <h4 class="font-weight-bold">
                (Optional) Select one or more merchandise
              </h4>
            </div>
            <section :class="itemView">
              <b-card
                v-for="product in eventData.products"
                :key="product._id"
                class="ecommerce-card"
                no-body
              >
                <div class="item-img text-center">
                  <b-img
                    v-if="getValueFromSource(product, 'featured_image.path')"
                    :alt="`${product.name}`"
                    fluid
                    class="card-img-top"
                    :src="getValueFromSource(product, 'featured_image.path')"
                  />
                  <b-img
                    v-else
                    :alt="`${product.name}`"
                    fluid
                    class="card-img-top"
                    src="@/assets/images/others/no-image.png"
                  />
                </div>
  
                <b-card-body>
                  <div class="item-wrapper">
                    <div>
                      <h6 class="item-price">
                        GHS {{ formatMoney(product.price) }}
                      </h6>
                    </div>
                  </div>
                  <h6 class="item-name">
                    <b-link
                      class="text-body"
                      :to="{ name: 'apps-e-commerce-product-details', params: { slug: product.slug } }"
                    >
                      {{ product.name }}
                    </b-link>
                  </h6>
                  <b-card-text class="item-description">
                    {{ product.description }}
                  </b-card-text>

                  <div v-if="isInCart(product)" class="item-quantity">
                    <span class="quantity-title">Quantity:</span>
                    <b-form-spinbutton
                      v-model="product.quantity"
                      size="sm"
                      class="ml-75"
                      inline
                    />
                  </div>
                </b-card-body>
  
                <div class="item-options text-center">
                  <div class="item-wrapper">
                    <div class="item-cost">
                      <h4 class="item-price">
                        GHS {{ formatMoney(product.price) }}
                      </h4>
                    </div>
                  </div>

                  <b-button
                    :variant="isInCart(product) ? 'danger' : 'primary'"
                    tag="a"
                    class="btn-cart"
                    @click="isInCart(product) ? removeProductToReservation(product) : addProductToReservation(product)"
                  >
                    <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"
                    />
                    <span>{{ isInCart(product) ? 'Remove from Reservation' : 'Add to Reservation' }}</span>
                  </b-button>
                </div>
              </b-card>
            </section>
          </div>

          <div v-else>
            <b-alert show variant="info" class="p-2">
              <p>No product required. Proceed</p>
            </b-alert>
          </div>
        </tab-content>

        <tab-content title="Confirmation">
          <div>
            <h4 class="font-weight-bold text-dark mb-2">
              Kindly confirm your reservation
            </h4>

            <hr />

            <app-timeline class="mt-2">
              <app-timeline-item
                v-if="getSelectedAccommodation"
                title="Accommodation"
                subtitle="Your selected accommodation"
                icon="HomeIcon"
                time=""
                variant="success"
              >
                <div>
                  <p class="text-muted">Accommodation</p>
                  <h4 class="m-0 mb-1">
                    {{ getSelectedAccommodation.title }}
                  </h4>
                  
                  <p class="text-info m-0">Price: {{ getSelectedAccommodation.is_free ? 'Free' : `GHS ${formatMoney(getSelectedAccommodation.price)}` }}</p>
                  <p v-if="!getSelectedAccommodation.is_free" class="text-info m-0">Allow part payment?: {{ getSelectedAccommodation.payment_installment ? 'Yes' : 'No' }}</p>
                </div>
              </app-timeline-item>

              <app-timeline-item
                v-if="getSelectedCanteen"
                title="Canteen"
                subtitle="Your selected canteen/caterer"
                icon="CoffeeIcon"
                time=""
                variant="success"
              >
                <div>
                  <p class="text-muted">Canteen</p>
                  <h4 class="m-0 mb-1">
                    {{ getSelectedCanteen.title }}
                  </h4>

                  <div v-if="getValueFromSource(getSelectedCanteen, 'thumbnail_image.path')">
                    <b-img
                      :src="getValueFromSource(getSelectedCanteen, 'thumbnail_image.path')"
                      width="400"
                      rounded
                      fluid
                      class="mr-2 mb-1 mb-md-0"
                    />
                  </div>
                </div>
              </app-timeline-item>

              <app-timeline-item
                v-if="getSelectedProducts.length > 0"
                title="Merchandise"
                subtitle="Your selected merchandise"
                icon="GridIcon"
                time=""
                variant="success"
              >
                <p class="text-muted">Merchandise</p>
                <div class="d-flex flex-wrap">
                  <div v-for="(product, i) in getSelectedProducts" :key="`product-${i}`" class="mr-1 mb-1">
                    <h4 class="m-0 mb-1">
                      {{ `${product.name}(${product.quantity})` }}
                    </h4>
                    
                    <div v-if="getValueFromSource(product, 'featured_image.path')">
                      <b-img
                        :src="getValueFromSource(product, 'featured_image.path')"
                        width="200"
                        rounded
                        class=""
                      />
                    </div>

                    <p class="text-info m-0">Price: {{ `GHS ${formatMoney(product.price)}` }}</p>
                  </div>
                </div>
              </app-timeline-item>
            </app-timeline>

            <div class="check-out-total w-50 mt-4">
              <div class="d-flex justify-content-between">
                <p class="invoice-total-title">
                  Accommodation:
                </p>
                <p class="font-weight-bold text-lg">
                  GHS {{ formatMoney(getAccommodationCost) }}
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="invoice-total-title">
                  Canteen:
                </p>
                <p class="font-weight-bold text-lg">
                  GHS {{ formatMoney(getCanteenCost) }}
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="invoice-total-title">
                  Merchandise:
                </p>
                <p class="font-weight-bold text-lg">
                  GHS {{ formatMoney(getProductsCost) }}
                </p>
              </div>
              <hr class="my-50">
              <div class="d-flex justify-content-between">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="font-weight-bold text-lg text-dark">
                  GHS {{ formatMoney(getAccommodationCost + getCanteenCost + getProductsCost) }}
                </p>
              </div>
            </div>
            
          </div>
        </tab-content>
      </form-wizard>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BAvatar, BMediaAside, BListGroup, BListGroupItem,
  BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend, BFormRadioGroup,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, 
  BFormCheckbox, BButton, BCardBody, BProgress, BProgressBar, BFormRadio, BFormSpinbutton
} from 'bootstrap-vue'

import { get } from "lodash";
import { kFormatter } from '@core/utils/filter'
import { required } from '@validations';
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import Ripple from 'vue-ripple-directive'
import VueCountdown from '@chenfengyuan/vue-countdown';
import ListEvents from "@/@core/components/shared/events/ListEvents.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BAlert,
    BMedia,
    BAvatar,
    BOverlay,
    BFormInput,
    BMediaBody,
    BMediaAside,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BCardBody,
    VueCountdown,
    BProgress, 
    BProgressBar,
    BFormRadio,
    BFormRadioGroup,
    BListGroup, 
    BListGroupItem,
    BFormSpinbutton,

    AppTimeline,
    AppTimelineItem,

    FormWizard,
    TabContent,
    ListEvents,
    ValidationObserver,
    ValidationProvider,
    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      eventData: null,

      itemView: 'grid-view',
      selectedAccommodation: null,
      selectedCanteen: null,
      selectedProducts: [],

      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],

      required
    }
  },
  computed: {
    getCountDownTime() {
      const start_date = this.eventData.event_start_date;
      const ms = Date.parse(start_date) - Date.now();
      return ms
    },
    getSelectedAccommodation() {
      return this.eventData.accommodations.find(opt => opt._id === this.selectedAccommodation)
    },
    getSelectedCanteen() {
      return this.eventData.canteens.find(opt => opt._id === this.selectedCanteen)
    },
    getSelectedProducts() {
      return this.eventData.products.filter(opt => this.selectedProducts.includes(opt.product_id))
    },
    getAccommodationCost() {
      return this.getSelectedAccommodation && !this.getSelectedAccommodation.is_free ? this.getSelectedAccommodation.price : 0
    },
    getCanteenCost() {
      return 0;
    },
    getProductsCost() {
      return this.getSelectedProducts.reduce((a, c) => a + (c.price * c.quantity), 0)
    }
  },
  created(){
    this.fetchEvent()
  },
  methods: {
    kFormatter,
    async fetchEvent() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().clientService.fetchEvent(id);
        const { data } = request.data;

        this.eventData = data;
      } catch (error) {
        this.eventData = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onReserveNow() {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Reservation?',
          text: "Do you want to proceed with this reservation?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        if (this.eventData.require_accommodation && !this.selectedAccommodation){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              variant: 'danger',
              text: `Kindly select an accommodation to proceed`
            },
          });
          return;
        }

        if (this.eventData.require_canteen && !this.selectedCanteen){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              variant: 'danger',
              text: `Kindly select a canteen to proceed`
            },
          });
          return;
        }

        const event_id = this.eventData._id;
        const payload = {
          accommodation_id: this.selectedAccommodation,
          canteen_id: this.selectedCanteen,
          products: this.getSelectedProducts
        };
       
        const response = await this.useJwt().clientService.makeReservation(event_id, payload);
        const { data } = response.data
        console.log('reservation', data)

        this.$router.push({ name: 'reservation-details', params: { reservation_id: data._id } });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Reservation successful`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validateAccommodationForm() {
      // return true
      return new Promise((resolve, reject) => {
        this.$refs.accommodationForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateCanteenForm() {
      // return true
      return new Promise((resolve, reject) => {
        this.$refs.canteenForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    computeRoomsAvailable(accommodation) {
      return (accommodation.number_of_persons_per_room * accommodation.number_of_rooms) - accommodation.reservations
    },
    canteenSlotsAvailable(canteen) {
      return canteen.capacity - canteen.reservations
    },
    addProductToReservation(product) {
      const id = product.product_id // product.type === 'product' ? product.product_id : product.product_option_id
      this.selectedProducts.push(id);
    },
    removeProductToReservation(product) {
      const id = product.product_id // product.type === 'product' ? product.product_id : product.product_option_id
      this.selectedProducts = this.selectedProducts.filter(opt => opt !== id);
    },
    isInCart(product){
      const id = product.product_id // product.type === 'product' ? product.product_id : product.product_option_id
      return this.selectedProducts.includes(id)
    }
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-wizard.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';

.custom-control-label {
  width: 50% !important;

  @media (max-width: 540px) {
    width: 100% !important;
  }
}

.check-out-total {
  @media (max-width: 540px) {
    width: 100% !important;
  }
}

.wizard-card-footer {
  padding: 0px !important;
}

.text-lg {
  font-size: 15px;
}
</style>
