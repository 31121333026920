var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('b-row',{class:{
  'd-flex': _vm.centered,
  'align-items-center': _vm.centered,
  'justify-content-center': _vm.centered
}},[_vm._l((_vm.items),function(event,i){return _c('b-col',{key:i+event._id,staticStyle:{"position":"relative","margin-top":"40px","margin-right":"10px","margin-left":"40px"},attrs:{"md":_vm.recentOnly ? 12 : 3}},[_c('div',{staticClass:"event-card-wrapper d-flex"},[_c('div',{staticClass:"blue-card"}),_c('div',{staticClass:"event-info"},[_c('h1',{staticClass:"event-title"},[_vm._v(_vm._s(event.title))]),_c('h4',{staticClass:"event-date"},[_vm._v(_vm._s(_vm.formatDuration(event.event_start_date, event.event_end_date)))]),_c('b-card-text',{staticClass:"event-description"},[_vm._v("For more information click on the button below")]),_c('b-button',{staticStyle:{"width":"80px"},attrs:{"variant":"primary","to":{ name: _vm.detailPage, params: { id: event._id } }}},[_vm._v(" More ")])],1)]),_c('b-img',{staticClass:"evt-thumbs",staticStyle:{"position":"absolute","width":"180px","height":"180px","top":"-20px","left":"-25px","border-radius":"20px"},attrs:{"src":_vm.getActualValueFromSource(
          event,
          'thumbnail_image.path',
          require('@/assets/images/pages/blog-default.webp')
        )}})],1)}),(_vm.showPagination)?_c('b-col',{attrs:{"cols":"12 mt-5"}},[_c('div',{staticClass:"my-2"},[_c('b-pagination',{attrs:{"value":_vm.currentPage,"total-rows":_vm.totalRecords,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"change":function($event){return _vm.$emit('update:currentPage', $event)}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388)})],1)]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }