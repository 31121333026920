<template>
  <b-row v-if="items.length > 0" :class="{
    'd-flex': centered,
    'align-items-center': centered,
    'justify-content-center': centered
  }"
  >
    <b-col
      v-for="(event, i) in items" :key="i+event._id" :md="recentOnly ? 12 : 3"
      style="position: relative; margin-top: 40px; margin-right: 10px; margin-left: 40px;"
    >
      <div class="event-card-wrapper d-flex">
        <div class="blue-card"></div>

        <div class="event-info">
          <h1 class="event-title">{{ event.title }}</h1>
          <h4 class="event-date">{{ formatDuration(event.event_start_date, event.event_end_date) }}</h4>
          <b-card-text class="event-description">For more information click on the button below</b-card-text>
          <b-button class="" style="width: 80px" variant="primary" :to="{ name: detailPage, params: { id: event._id } }">
            More
          </b-button>
        </div>
      </div>

      <b-img
        :src="getActualValueFromSource(
            event,
            'thumbnail_image.path',
            require('@/assets/images/pages/blog-default.webp')
          )"
        class="evt-thumbs"
        style="position: absolute; width: 180px; height: 180px; top: -20px; left: -25px; border-radius: 20px;"
      />
    </b-col>
  
    <!-- <b-col v-for="(content, i) in items" :key="i" :md="recentOnly ? 12 : 3" class="mb-2">
      <b-link class="d-block event-link" :to="{ name: detailPage, params: { id: content._id } }" :target="recentOnly ? '_blank' : '_self'">
        <div class="event-wrapper position-relative">
          <b-img :src="getValueFromSource(content, 'flyer_image.path')" class="w-100"></b-img>

          <div class="event-overlay">
            <h1 class="text-white text-right mr-2 mt-2 font-xl mb-0 pb-0">{{ formatDate(getValueFromSource(content, 'event_end_date'), 'dd') }}</h1>
            <p class="text-white text-right mr-2">{{ formatDate(getValueFromSource(content, 'event_end_date'), 'MMM, yyyy') }}</p>

            <div class="event-title px-2">
              <div class="d-flex">
                <p class="text-white text-uppercase mb-0">
                  <feather-icon icon="TagIcon" color="orange"></feather-icon>
                  {{ getValueFromSource(content, 'event_type') }}
                </p>
                <p class="text-white text-uppercase mb-0 ml-1">
                  <feather-icon icon="MapPinIcon" color="orange"></feather-icon>
                  {{ getValueFromSource(content, 'event_venue') }}
                </p>
              </div>
              <h2 class="text-white font-lg">{{ getValueFromSource(content, 'title') }}</h2>
            </div>
          </div>
        </div>
      </b-link>
    </b-col> -->

    <b-col v-if="showPagination" cols="12 mt-5">
      <!-- pagination -->
      <div class="my-2">
        <b-pagination :value="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" @change="$emit('update:currentPage', $event)">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BImg
} from "bootstrap-vue";
import { isPast } from 'date-fns';

export default {
  name: "BlogCard",
  components: {
    BCard,
    BRow,
    BOverlay,
    BLink,
    BCol,
    BCardText,
    BCardBody,
    BCardTitle,
    BImg,
    BButton,
    BPagination
  },
  props: {
    currentPage: { type: Number, required: true },
    totalRecords: { type: Number, required: true },
    perPage: { type: Number, required: true },
    detailPage: { type: String, required: true },
    showBadge: { type: Boolean, default: false },
    items: { type: Array, required: true },
    showPagination: { type: Boolean, default: true },
    recentOnly: {
      type: Boolean,
      default: false
    }, 
    centered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isPast(event_start_date){
      const today = new Date();
      const start_date = new Date(event_start_date);
      return isPast(start_date, today);
    }
  }
}
</script>

<style lang="scss">
.swiper-slide {
  position: relative;
}

.event-card-wrapper {
  width: 300px;
  height: 170px;

  .blue-card {
    background-color: #0f00ad;
    width: 50%;
    height: 170px;
  }
  .event-info {
    width: 50%;
    padding-left: 5px;

    .event-title {
      font-size: 16px;
      font-weight: bold;
      color: #0f00ad;
    }

    .event-date {
      text-transform: uppercase;
      font-weight: bold;
      color: #4c4c4c;
      margin: 0px;
      font-size: 12px;
    }

    .event-month {
      text-transform: uppercase;
      font-weight: bold;
      color: #4c4c4c;
      font-size: 16px;
    }
  }
}

.events-container {
  height: 250px;
}

.events-container::-webkit-scrollbar {
  display: none;
}
.upcoming-events {
  padding-bottom: 50px;
  background-color: #f6f6f6;
}

.event-link {
  .event-overlay {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0
  }
  :hover {
    transition: transform .2s;
    .event-overlay {
      background: rgba(13, 33, 116, 0.6);
    }
    transform: scale(1.1);
  }
  .font-lg {
    font-size: 25px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.85);
  }
  .font-xl {
    font-size: 50px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.85);
  }
  .event-title {
    position: absolute;
    bottom: 0;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
